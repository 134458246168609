import { ItemList } from '../orm'
import cookie from 'react-cookies'

class MolliePaymentList extends ItemList {

    getEntityKey() {
        return "financial/mollie_payment"
    }
    
    startBuyTopup(topup) {
        return this.saveNewObject({topup:topup.id})
    }

    startPaySubscription({subscription, agreedTermsAndConditions}) {
        return this.saveNewObject({subscriptions: [subscription.id],
                                   agreed_to_terms_and_conditions: agreedTermsAndConditions})
    }

    rememberAsReturnUrl(url) {
        const return_url = url || window.location.pathname
        cookie.save('mollie_return_url', return_url, { path: '/'})
    }

    tryReturn(history) {
        const current_url = window.location.pathname
        if ( current_url === '/buy_credit_topup' || current_url === '/pay_subscriptions' ) {
            // already on a page which would have originally triggered the mollie payment
            return
        }
        const url = cookie.load('mollie_return_url')
        if ( url ) {
            cookie.remove('mollie_return_url', { path: '/' })
            history.push(url)
        }
    }
    
    rememberPendingMolliePaymentId(mollie_payment_id) {
        cookie.save('mollie_payment_id', mollie_payment_id, { path: '/'})
    }

    checkPendingMolliePaymentStatus(on_checked) {
        return async(dispatch, getState) => {
            const mollie_payment_id = cookie.load('mollie_payment_id')
            if ( ! mollie_payment_id ) {
                return
            }
            dispatch(this.invalidateObjects([mollie_payment_id]))
            dispatch(this.ensureObjectLoaded(mollie_payment_id)).then( (json) => {
                cookie.remove('mollie_payment_id', { path: '/'})
                const mollie_record = this.getObject(mollie_payment_id)
                on_checked(mollie_record)
            })
        }
    }
    
}

export const molliePaymentList = new MolliePaymentList("mollie_payment_list__default")

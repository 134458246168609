/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { map, union, head, get, filter } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import AdminTableFilter from './AdminTableFilter'
import { CardHeader } from '../../components/layout/CardHeader'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import { WhiteButton } from '../../components/layout/WhiteButton'
import { adminNumberTypes } from '../actions/admin_dropdown_options'
import { AdminQuickLink } from './AdminQuickLink'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { Trans, Translation } from 'react-i18next'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { SubscriptionAmount } from '../../components/SubscriptionAmount'
import AdminTableHeader from './AdminTableHeader'
import { AdminTableFilterPopup } from './AdminTableFilterPopup'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Row, Col } from 'react-bootstrap'
import { PhoneNumber } from '../../components/PhoneNumber'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { InlineIconBar } from '../../components/layout/InlineIconBar'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import AdminCustomerSubscription from './AdminCustomerSubscription'
import { adminSubscriptionStatusList } from '../actions/admin_subscription_status'
import { adminProductList } from '../actions/admin_product'
import { adminResellerList } from '../actions/admin_reseller'
import { PAYMENT_TYPE_OPTIONS } from './AdminCustomerSubscription'

export const NEW_SUBSCRIPTION_ID = "new"

const REPRESENTS_ACTIVE_SUBSCRIPTION_OPTIONS = [ {value: true, label: 'Active'},
                                                 {value: false, label: 'Inactive'},
                                                 {value: null, label: 'Any'} ]

class AdminCommonSubscriptions extends Component {

    constructor(props) {
        super(props)
        this.state = {show_advanced_filter: false}
    }
    
    componentDidMount() {
        const { dispatch, customer_id, subscriptionList } = this.props
        dispatch(subscriptionList.fetchListIfNeeded())
        dispatch(adminResellerList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminResellerList.fetchListIfNeeded())
        dispatch(adminSubscriptionStatusList.updatePaginationNumItemsPerPage(100))
        dispatch(adminSubscriptionStatusList.fetchListIfNeeded())
        dispatch(adminProductList.updatePaginationNumItemsPerPage(100))
        dispatch(adminProductList.fetchListIfNeeded())
        dispatch(adminNumberTypes.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, customer_id, subscriptionList } = this.props
        dispatch(subscriptionList.fetchListIfNeeded())
        dispatch(adminProductList.fetchListIfNeeded())
    }

    onEditSubscription = (item) => {
        const { history } = this.props
        history.push(`/admin/customer/${item.customer}/subscription/${item.id}`)
    }

    renderUpgradeFromSubscription = (subscription) => {
        const { customer_subscriptions } = this.props
        const from_subscription = head(filter(customer_subscriptions, (sub) => sub.id === subscription.upgrade_from_subscription))

        return (
            <div css={supplement_value_style}>
              { from_subscription &&
                <>
                  <Trans>Upgrade from</Trans>
                  &nbsp;
                  {get(from_subscription, "product_name")}
                  &nbsp;<PhoneNumber phone_number={get(from_subscription, "phone_number_number")} />
                </>
              }
            </div>
        )
    }

    renderUpgradedToSubscription = (subscription) => {
        const { customer_subscriptions } = this.props
        const to_subscription = head(filter(customer_subscriptions, (sub) => sub.id === subscription.upgraded_to_subscription))

        return (
            <div css={supplement_value_style}>
              { to_subscription &&
                <>
                  <Trans>Upgraded to</Trans>
                  &nbsp;
                  {get(to_subscription, "product_name")}
                  &nbsp;<PhoneNumber phone_number={get(to_subscription, "phone_number_number")} />
                </>
              }
            </div>
        )
    }

    getCellValue = (header_key, item, index) => {
        const { subscription_statuses, number_types_by_id } = this.props
        const that = this
        switch( header_key ) {
            case 'product_name':
                return (
                    <>
                      <div>{item.product_name}</div>
                      { map(item.supplement_number_types, (supplement, index) => 
                            <div key={index} css={supplement_value_style}>{get(number_types_by_id, [supplement.number_type, "name"])}</div>
                           )
                      }
                    </>
                )
            case 'number':
            return (
                <>
                  <AdminQuickLink name="phone_number" value={item.phone_number} label={<PhoneNumber phone_number={get(item, "phone_number_number")} />} />
                  { item.upgrade_from_subscription && that.renderUpgradeFromSubscription(item) }
                  { item.upgraded_to_subscription && that.renderUpgradedToSubscription(item) }
                </>
            )
            case 'status':
                const status = get(subscription_statuses, item.status)
                return (
                    <>
                      {get(status, "display_name")}
                      { get(status, "represents_active_subscription") && <InlineIcon icon_name="tick" /> }
                    </>
                )
            case 'price':
            return (
                <>
                  <div css={price_style}>
                    <SubscriptionAmount subscription={item} />
                  </div>
                  { map(item.supplement_number_types, (supplement, index) => 
                        <div key={index} css={supplement_value_style}>
                          <SubscriptionAmount subscription={item} value_override={supplement.price_per_year_excluding_vat_euros} />
                        </div>
                       )
                  }
                </>
            )
            case 'billing_frequency':
                if ( item.billing_frequency === 'monthly' ) {
                    return "Monthly"
                } else if ( item.billing_frequency === 'annually' ) {
                    return "Yearly"
                } else {
                    return item.billing_frequency
                }

            case 'created_at':
                return <Timestamp value={item.created_at} format="date" />
            case 'expires_at':
                return <Timestamp value={item.expires_at} format="date" emptyLabel="When inactive" />
            case 'payment_type':
                return get(head(filter(PAYMENT_TYPE_OPTIONS, (x) => x.value === item.payment_type)), "label")
            case 'action':
                return (
                    <InlineIconBar>
                      <InlineIcon icon_name="edit" onClick={() => this.onEditSubscription(item)}/>
                    </InlineIconBar>
                )
            default:
                return undefined
        }
    }

    onFilterChanged = (filter_values) => {
        const { dispatch, subscriptionList } = this.props
        dispatch(subscriptionList.updateListFilter(filter_values))
    }

    onShowAdvancedFilter = () => {
        this.setState({show_advanced_filter: true})
    }

    onHideAdvancedFilter = () => {
        this.setState({show_advanced_filter: false})
    }

    renderFilterFields = (formik_props) => {
        const { subscription_state_options, product_options, show_filter, reseller_options } = this.props

        if ( ! show_filter ) {
            return null
        }
        
        return (
            <AdminTableFilterPopup popup_fields={(
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <FormikDropdownField name="product"
                                             formik_props={formik_props}
                                             options={product_options}
                                             placeholder="Product"
                        />
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col md="5">
                        <FormikDatePicker extra_css={filter_date_picker_style} name="expires_after" placeholder="Expires after" can_clear={true} formik_props={formik_props} />
                      </Col>
                      <Col md="5">
                        <FormikDatePicker extra_css={filter_date_picker_style} name="expires_before" placeholder="Expires before" can_clear={true} formik_props={formik_props} />
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col md="5">
                        <FormikDatePicker extra_css={filter_date_picker_style} name="created_after" placeholder="Created after" can_clear={true} formik_props={formik_props} />
                      </Col> 
                      <Col md="5">
                        <FormikDatePicker extra_css={filter_date_picker_style} name="created_before" placeholder="Created before" can_clear={true} formik_props={formik_props} />
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="status"
                                             formik_props={formik_props}
                                             options={subscription_state_options}
                                             placeholder="Status"
                                             extra_css={subscription_state_filter_style}
                        />
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="reseller"
                                             options={reseller_options}
                                             placeholder="Reseller"
                                             extra_css={reseller_filter_style}
                                             formik_props={formik_props} />
                      </Col>
                    </Row>
                    <Separator variant="h5" />
                    <Row>
                      <Col>
                        <FormikDropdownField name="represents_active_subscription"
                                             formik_props={formik_props}
                                             options={REPRESENTS_ACTIVE_SUBSCRIPTION_OPTIONS}
                                             placeholder="Active"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
            )}
                                   fixed_fields={(
                                       <Row>
                                         <Col>
                                           <FormikInputField name="any_field"
                                                             placeholder="Search"
                                                             show_search_icon={true}
                                           />
                                         </Col>
                                       </Row>
                                   )}
            />
        )
    }
    
    renderFilter() {
        const that = this
        return (
            <div css={filter_style}>
              <AdminTableFilter updateOnChange={ that.onFilterChanged }
                                renderFilter={that.renderFilterFields}
              />
            </div>
        )
    }

    render() {
        const { is_loading, headers, supplementary_headers, is_ready, title, subscriptionList, customer_subscriptions, customerList, additional_header } = this.props
        const that = this

        return (
            <div>
              <CardHeader title={title || "Subscriptions"}
                          right_child={<div css={right_child_style}>
                                         { that.renderFilter() }
                                         { additional_header }
                                       </div>}
              />
              
              <CommonTable
                is_loading={ is_loading }
                empty_message={`There are no matching subscriptions.`}
                headers={ Object.assign({}, supplementary_headers, headers) }
                items={ customer_subscriptions }
                item_list={subscriptionList}
                getCellValue={ that.getCellValue }
              />
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { customer_id, customer_subscription_id, subscriptionList, supplementary_headers } = props
    const customer_subscriptions = subscriptionList.getVisibleObjects()
    const subscription_statuses = adminSubscriptionStatusList.getObjectsById()
    return {
        customer_subscription_id,
        customer_subscriptions,
        is_loading: subscriptionList.isLoading(),
        is_ready: subscriptionList.isReady(),
        subscription_statuses,
        subscription_state_options: adminSubscriptionStatusList.getAsOptions({empty_option_label:'All states'}),
        product_options: adminProductList.getAsOptions({empty_option_label:'All products'}),
        reseller_options: adminResellerList.getAsOptions({empty_option_label:'All resellers'}),
        headers: {
            product_name: { name: 'Subscription' },
            number: { name: 'Number' },
            price: { name: 'Price' },
            billing_frequency: { name: 'Cycle' },
            created_at: { name: 'Created at' },
            status: { name: 'Status' },
            expires_at: { name: 'Expires at' },
            payment_type: { name: 'Payment type' },
            action: { name: '' }
        },
        supplementary_headers,
        number_types_by_id: adminNumberTypes.getObjectsById()
    }
}

export default withRouter(connect(mapStateToProps)(AdminCommonSubscriptions))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const price_style = css`
display: flex;
`

const right_child_style = css`
display: flex;
`

const filter_date_picker_style = css`
margin-right: 2px;
`

const subscription_state_filter_style = css`
width: 200px;
`

const filter_style = css`
display: flex;
align-items: center;
`

const reseller_filter_style = css`
width: 400px;
`

const supplement_value_style = css`
color: ${theme.colors.middle_grey};

`
